.ajax_loader {
	background: url(../images/loader.svg) no-repeat center center;
	min-height: 80px;
}

.myicon {
	font-style: normal;

	&:before {
		content: '';
		display: block;
		background: url("../images/icons.png") no-repeat;
		width: 100%;
		height: 100%;
	}

	&.email:before {
		background-position: 0px -58px;
	}

	&.hospital:before {
		background-position: -45px -50px;
	}

	&.member:before {
		background-position: -90px -50px;
	}

	&.experience:before {
		background-position: 0px -10px;
	}

	&.document:before {
		background-position: -45px -10px;
	}

	&.gid:before {
		background-position: -90px -10px;
	}



}

.burger-h {
	display: inline-block;
	width: 30px;
	height: 20px;
	background: url("../images/icons.png") no-repeat right -100px;
}

.process-i {
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../images/icons.png") no-repeat 0px -100px;
}

.icon-copy {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		//background: url("../images/ic-copy.svg") no-repeat;
		@include copy_icon($fill_primary);
		background-size: contain;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}

	&.active {
		&:before {
			@include copy_icon($fill_success);
		}
	}

	&.text-primary::before {
		@include copy_icon($fill_secondary);
	}
}

.icon_view {
	font-style: normal;
	width: 20px;
	height: 20px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include view_icon($fill_primary);
		background-repeat: no-repeat
	}

	&.text-primary {
		&:after {
			@include view_icon($fill_secondary);
		}
	}
}


.icon_phone {
	font-style: normal;
	width: 20px;
	height: 20px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include phone_icon($fill_primary);
		background-repeat: no-repeat
	}
}
.icon_phone_gray {
	font-style: normal;
	width: 20px;
	height: 20px;
	display: inline-block;
	&:after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	@include phone_header_icon($fill_secondary);
	background-repeat: no-repeat
	}
	}
.icon_star {
	font-style: normal;
	width: 20px;
	height: 20px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include star_icon($fill_primary);
		background-repeat: no-repeat
	}

	&.favourite {
		&:after {
			@include fillstar_icon('fb8805')
		}
	}
}

.icon_video {
	font-style: normal;
	width: 25px;
	height: 15px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include video_icon('000');
		background-repeat: no-repeat
	}
}

.icon_opd {
	font-style: normal;
	width: 25px;
	height: 15px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include opd_icon('000');
		background-repeat: no-repeat
	}
}

.icon_delete,
.inner_delete {
	font-style: normal;
	width: 16px;
	height: 18px;
	display: inline-block;

	&:after {
		opacity: 0.9;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: url(../images/ic-delete.svg) no-repeat center center;
		background-size: contain;
	}
}

.icon_delete_all{
  width: 24px;
	height: 24px;
}

.icon-home {
	display: inline-block;
	width: 20px;
	height: 20px;

	&:before {
		content: '';
		display: block;
		@include home_icon($fill_black);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-cancel {
	position: relative;
	display: inline-block;

	&::before,
	&::after {
		content: '';
		width: 1.2rem;
		height: .12rem;
		background: $black;
		display: inline-block;
		position: absolute;
		transform: rotate(45deg);
		top: -0.6rem;
		left: -0.6rem;
	}

	&::after {
		transform: rotate(135deg);
	}
}

.icon-c-cancel {
	position: relative;
	border: solid 0.12rem #ccc;
	border-radius: 50%;
	display: block;
	width: 1.75rem;
	height: 1.75rem;

	&::before,
	&::after {
		content: '';
		width: 1rem;
		height: .12rem;
		background: rgba(0, 0, 0, .3);
		display: block;
		position: absolute;
		transform: rotate(45deg);
		top: 0.75rem;
		left: 0.25rem;
	}

	&::after {
		transform: rotate(135deg);
	}
}

.text-secondary {
	.icon-c-cancel {
		border-color: $primary;

		&::before,
		&::after {
			background-color: $primary;
		}
	}
}

.icon-pause {
	display: inline-block;
	width: 15px;
	height: 15px;

	&:before {
		content: '';
		display: block;
		@include pause_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

.icon-minus {
	display: inline-block;
	width: 26px;
	height: 26px;

	&:before {
		content: '';
		display: block;
		@include minus_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-anti-clock {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include anti_clock_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

.icon-clock {
	display: inline-block;
	width: 20px;
	height: 20px;

	&:before {
		content: '';
		display: block;
		@include clock_icon($fill_black);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}


.icon-doc-copy {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include doc_copy_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

.icon-print {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include print_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

.icon-print {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include print_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

.icon-edit {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		opacity: 0.7;
		@include edit_icon($fill_black);
		background-repeat: no-repeat;
		background-size: contain;
		width: 100%;
		height: 100%;
	}
}

.icon-calculator {
	display: inline-block;
	width: 30px;
	height: 32px;

	&:before {
		content: '';
		display: block;
		@include calculator_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-calendar {
	display: inline-block;
	width: 22px;
	height: 22px;

	&:before {
		content: '';
		display: block;
		opacity: 0.75;
		@include calendar_icon($fill_secondary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-plus {
	display: inline-block;
	width: 26px;
	height: 26px;

	&:before {
		content: '';
		display: block;
		@include plus_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon_substitutes {
	display: inline-block;
	width: 38px;
	height: 38px;

	&:before {
		content: '';
		display: block;
		@include substitutes_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon_info {
	display: inline-block;
	width: 24px;
	height: 24px;

	&:before {
		content: '';
		display: block;
		@include info_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-circle-left {
	display: inline-block;
	width: 22px;
	height: 22px;
	background-color: $secondary;
	border-radius: 50%;

	&:before {
		content: '';
		display: block;
		position: relative;
		top: 0.375rem;
		left: 4px;
		@include arrow_icon($fill_white);
		background-repeat: no-repeat;
		background-size: cover;
		width: 45%;
		height: 45%;
		transform: rotate(90deg);
	}

	&:hover {
		background-color: $bg_dark;
	}
}

.icon-circle-right {
	display: inline-block;
	width: 22px;
	height: 22px;
	background-color: $secondary;
	border-radius: 50%;

	&:before {
		content: '';
		display: block;
		position: relative;
		top: 0.375rem;
		left: 7px;
		@include arrow_icon($fill_white);
		background-repeat: no-repeat;
		background-size: cover;
		width: 45%;
		height: 45%;
		transform: rotate(-90deg);
	}

	&:hover {
		background-color: $bg_dark;
	}
}

.icon-severity-low {
	display: inline-block;
	min-width: 18px;
	width: 18px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include severity_icon($fill_severity_low);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: relative;
		top: 2px;
	}
}

.icon-severity-medium {
	display: inline-block;
	width: 18px;
	min-width: 18px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include severity_icon($fill_severity_medium);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: relative;
		top: 2px;
	}
}

.icon-severity-high {
	display: inline-block;
	width: 18px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include severity_icon($fill_severity_high);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: relative;
		top: 2px;
	}
}

.icon-severity-gray {
	display: inline-block;
	width: 18px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include severity_icon($fill_severity_gray);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}


.icon-chart {
	display: inline-block;
	width: 24px;
	height: 24px;

	&:before {
		content: '';
		display: block;
		@include chart_icon;
		background-repeat: no-repeat;
		background-size: contain;
		width: 100%;
		height: 100%;
	}

}

.icon-addfile {
	display: inline-block;
	width: 18px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include addfile_icon($fill_secondary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-move {
	display: inline-block;
	width: 16px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include move_icon($fill_secondary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-medinfo {
	display: inline-block;
	width: 24px;
	height: 28px;

	&:before {
		content: '';
		display: block;
		@include medinfo_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-jpg {
	display: inline-flex;
	width: 24px;
	height: 28px;

	&:before {
		content: '';
		display: block;
		@include jpg_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-pdf {
	display: inline-flex;
	width: 24px;
	height: 28px;

	&:before {
		content: '';
		display: block;
		@include pdf_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-pdf-small {
	display: inline-flex;
	width: 16px;
	height: 20px;

	&:before {
		content: '';
		display: block;
		@include pdf_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

img.card_icons {
	width: 24px;
	height: 24px;
	object-fit: contain;
  }