/* .hundredms-overlay{position: fixed;top: 0;right: 0;bottom: 0;left: 0;background-color: #ffffff;z-index: 999 !important}
.hundredms-header{width: 100%;float: left; text-align: left;padding: 10px 15px;}
.hundredms-body{width: 100%;float: left;text-align: left;}
.hundredms-small-overlay{position: fixed;top: 5%;left: 10%;background-color: #ffffff;z-index: 999 !important;overflow-y: auto; width: 400px;height: 600px !important;} */

.hundredms-overlay{position: fixed;top: 0;right: 0;bottom: 0;left: 0;background-color: #ffffff;z-index: 9999 !important}
.hundredms-header{width: 100%;float: left; text-align: left;padding: 10px 15px;}
.hundredms-body{width: 100%;float: left;text-align: left;}
.hundredms-small-overlay{position: fixed;bottom: 5px;left:5px;background-color: #ffffff;z-index: 811 ;overflow-y: auto;width: 235px;height: 340px !important;}
.hundredms-verysmall-overlay {
    position: fixed;
    bottom: 5px;
    left: 5px;
    background-color: #ffffff;
    z-index: 999 !important;
    overflow-y: auto;
    width: 100px;
    height: 72px !important;
    border: 1px solid #d9d9d9; /* Add red border */
}
.hundredms-header-small{width: 100%;height:1px; float: left; text-align: left;padding: 10px 15px;}
